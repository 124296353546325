@import './common.scss';

.specialties {
    text-align: center;
    background-color: rgba(#E5E7E9, 0.5);

    &.yellow {
        .section-title {
            h1 {
                color: $yellow;
            }
        }

        .description-wrapper {
            .title, .description {
                background-color: $yellow;
            }
        }
    }

    &.blue {
        .section-title {
            h1 {
                color: $blue;
            }
        }

        .description-wrapper {
            .title, .description {
                background-color: $blue;
            }
        }
    }

    &.green {
        .section-title {
            h1 {
                color: $green;
            }
        }

        .description-wrapper {
            .title, .description {
                background-color: $green;
            }
        }
    }

    &.red {
        .section-title {
            h1 {
                color: $red;
            }
        }

        .description-wrapper {
            .title, .description {
                background-color: $red;
            }
        }
    }

    &.gray {
        .section-title {
            h1 {
                color: $gray;
            }
        }

        .description-wrapper {
            .title, .description {
                background-color: $gray;
            }
        }
    }

    .section-title {
        margin-bottom: 35px;
    }

    .specialties-wrapper {
        padding-top: 10px;

        .specialty-wrapper {
            display: inline-block;
            vertical-align: middle;
            box-sizing: border-box;
            text-align: center;

            .specialty {
                opacity: 0.35;

                &.selected {
                    opacity: 1;
                }

                .title {
                    display: block;
                    margin-top: 15px;
                    font-family: $mirai-bold;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    color: #394856;
                }
            }
        }
    }

    .description-wrapper {
        color: white;

        .title {
            width: 190px;
            height: 100px;
            position: relative;
            padding: 0 20px;
            box-sizing: border-box;

            h1 {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                font-family: $mirai-bold;
                font-size: 16px;
                color: white;
            }
        }

        .image {
            width: 220px;
            height: 240px;
            position: relative;
            background-color: white;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .description {
            width: 440px;
            height: 260px;
            padding: 20px $default-padding;
            box-sizing: border-box;

            p {
                margin-bottom: 0;
                font-family: $mirai-regular;
                font-size: 16px;
                line-height: 30px;
                letter-spacing: 0.5px;
                text-align: left;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@include media('>=tablet') {
    .specialties {
        .specialties-wrapper {
            width: 100%;
            padding-bottom: 70px;
            box-sizing: border-box;
            text-align: center;

            .specialty-wrapper {
                width: 20%;
                max-width: 160px;
                padding: 0 10px;
                box-sizing: border-box;

                .specialty {
                    &:hover {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }

        .description-wrapper {
            .title, .image, .description {
                display: inline-block;
                vertical-align: top;
            }

            .title, .description {
                margin-top: 30px;
            }
        }
    }
}

@media only screen and (max-width: 950px) {
    .specialties {
        .description-wrapper {
            max-width: 440px;
            margin: auto;

            .title, .image, .description {
                display: block;
            }

            .title, .description {
                margin-top: 0;
            }

            .title {
                margin-bottom: -20px;
                z-index: 1;
            }

            .description {
                height: auto;
                margin-top: -20px;
            }

            .image {
                margin-left: 50px;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@include media('<tablet') {
    .specialties {
        .specialties-wrapper {
            padding-bottom: 40px;

            .specialty-wrapper {
                width: 33.33%;
                padding: 15px 10px;
            }
        }

        .description-wrapper {
            width: 440px;
            margin: auto;

            .title, .image, .description {
                display: block;
            }

            .title {
                margin-bottom: -20px;
                z-index: 1;
            }

            .description {
                margin-top: -20px;
            }

            .image {
                margin-left: 50px;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .specialties {
        .description-wrapper {
            width: auto;

            .description {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 375px) {
    .specialties {
        .specialties-wrapper {
            padding-bottom: 30px;

            .specialty-wrapper {
                padding: 15px 3px;

                .specialty {
                    img {
                        height: 60px;
                    }

                    .title {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
