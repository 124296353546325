@import './common.scss';

.slideshow {
    @include image169;
    width: 100%;
    min-height: 500px;
    max-height: 95vh;
    overflow: hidden;
    background-color: $brand-primary-color;

    .image, .text {
        z-index: 2;
        transition: opacity 2s;

        &.hidden, &.fade-out {
            opacity: 0;
        }

        &.fade-in {
            opacity: 1;
            z-index: 3;
        }
    }

    .overlay {
        width: 100%;
        height: 100%;

        .centered {
            height: 100%;
            position: relative;
        }

        .text {
            max-width: 540px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.hidden, &.fade-out {
                z-index: 0;
            }

            a {
                text-decoration: none;
                color: white;
                cursor: pointer;

                h1 {
                    display: inline;
                    margin: 0;
                    line-height: 47px;
                    letter-spacing: 1px;
                    font-family: $mirai-bold;
                    font-size: 38px;

                    &.coloured {
                        color: $green;
                    }
                }

                .icon-go-right {
                    display: block;
                    margin-top: 10px;
                    font-size: 24px;
                }
            }
        }

        .indicators {
            position: absolute;
            right: 0;
            left: 0;
            text-align: center;
            z-index: 3;

            .indicator {
                width: 10px;
                height: 10px;
                display: inline-block;
                padding: 0 7px;

                span {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    border-radius: 50%;
                    background-color: #D6D9DC;
                    opacity: 0.5;
                }

                &.selected {
                    span {
                        background-color: white;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@include media('>=tablet') {
    .slideshow {
        .overlay {
            .text {
                left: $default-padding;
            }

            .indicators {
                bottom: 30px;

                .indicator {
                    &:hover {
                        cursor: pointer;

                        span {
                            background-color: white;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@include media('<tablet') {
    .slideshow {
        .overlay {
            .text {
                right: $default-padding-mobile;
                left: $default-padding-mobile;

                h1 {
                    font-size: 30px;
                }
            }

            .indicators {
                bottom: 25px;
            }
        }
    }
}
