@import './common.scss';

.services {
    background-color: #E5E7E9;

    .section-title {
        margin-bottom: 35px;

        h1 {
            color: $red;

            &::before {
                background-color: $red;
            }
        }
    }

    p {
        margin-top: 30px;
        line-height: 30px;
        letter-spacing: 0.5px;
        font-family: $mirai-regular;
        font-size: 16px;
        color: #394856;
    }

    .services-wrapper {
        padding-bottom: 70px;

        .service-wrapper {
            vertical-align: top;

            .service {
                position: relative;
                display: inline-block;
                padding: 40px 30px;
                border-radius: 3px;
                background-color: white;
                box-shadow: 0 0 25px 2px rgba(0, 0, 0, 0.08);
                color: $brand-primary-color;

                .icon {
                    display: block;
                    text-align: center;
                    font-size: 90px;
                    color: $red;
                }

                .title {
                    display: block;
                    margin-top: 60px;
                    letter-spacing: 0.5px;
                    font-family: $mirai-bold;
                    font-size: 16px;

                }
            }
        }

        a {
            text-decoration: none;

            &.more {
                margin-top: 25px;
                letter-spacing: 0.5px;
                line-height: 20px;
                font-family: $mirai-bold;
                font-size: 16px;
                color: $red;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@include media('>=tablet') {
    .services {
        .intro {
            max-width: 560px;
        }

        .services-wrapper {
            margin: auto;
            padding: 0 $default-padding / 2;
            padding-top: 70px;

            .service-wrapper {
                width: 33.33%;
                display: inline-block;
                box-sizing: border-box;
                padding: 20px;

                &.moved-up {
                    margin-top: -50px;
                }

                .service {
                    width: 100%;
                    height: 550px;
                    box-sizing: border-box;
                }
            }

            a {
                &.more {
                    display: inline-block;
                }
            }
        }
    }
}

@media only screen and (max-width: 1160px) {
    .services {
        .services-wrapper {
            text-align: center;

            .service-wrapper {
                width: 50%;
                max-width: 400px;

                .service {
                    .title, p {
                        text-align: left;
                    }
                }

                &.moved-up {
                    margin-top: 0;
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@include media('<tablet') {
    .services {
        .services-wrapper {
            padding: 15px 0;

            .service-wrapper {
                width: 100%;
                max-width: 400px;
                display: block;
                padding: 15px 0;
                margin: auto;
            }

            a {
                &.more {
                    display: block;
                }
            }
        }
    }
}
