@import './common.scss';

.services-home {
    .centered {
        padding-top: 75px;
        padding-bottom: 75px;
        text-align: center;
    }

    .section-title {
        text-align: center;
        margin-bottom: 35px;

        h1 {
            color: $red;
        }

        .intro {
            margin: auto;
            margin-top: 35px;
            line-height: 30px;
            letter-spacing: 0.5px;
            text-align: left;
            font-family: $mirai-regular;
            font-size: 16px;
            color: #394856;
        }
    }

    .services-wrapper {
        margin: auto;
        padding-bottom: 70px;
        text-align: center;

        .service-wrapper {
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;

            .service {
                @include service-item;
                position: relative;
                display: inline-block;
                padding: 20px 0;
                border-radius: 3px;
                box-sizing: border-box;
                background-color: rgba(#E7E8EB, 0.3);
            }
        }
    }

    a {
        display: inline-block;
        text-align: center;
        text-decoration: none;

        &.more {
            @include button-more;
            color: $red;
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@include media('>=tablet') {
    .services-home {
        .intro {
            max-width: 560px;
        }

        .services-wrapper {
            max-width: 850px;
            padding: 25px 0;

            .service-wrapper {
                width: 33.33%;
                max-width: 280px;
                padding: 15px;
                box-sizing: border-box;

                .service {
                    width: 100%;
                    padding: 25px;
                    text-align: left;

                    .icon, .title {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .icon {
                        margin-right: 15px;
                    }

                    .title {
                        margin-top: 0;
                    }

                    &:hover {
                        cursor: pointer;

                        .icon {
                            color: $red;
                        }

                        .title {
                            color: $brand-primary-color;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .services-home {
        .services-wrapper {
            .service-wrapper {
                max-width: 290px;
                padding: 15px 10px;

                .service {
                    padding: 20px 15px;
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@include media('<tablet') {
    .services-home {
        .centered {
            padding-left: $default-padding-mobile / 2;
            padding-right: $default-padding-mobile / 2;
        }

        .section-title {
            padding: 0 $default-padding-mobile / 2;
        }

        .services-wrapper {
            width: 615px;

            .service-wrapper {
                padding: 10px $default-padding-mobile / 2;

                .service {
                    width: 160px;
                }
            }
        }
    }
}

@media only screen and (max-width: 680px) {
    .services-home {
        .services-wrapper {
            width: 100%;

            .service-wrapper {
                width: 50%;
                max-width: 170px;

                .service {
                    width: 100%;
                }
            }
        }
    }
}
