@import './common.scss';

.brands {
    position: relative;
    background-color: #E5E7E9;
    overflow-y: hidden;

    .hero {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: visible;
    }

    .centered {
        position: relative;
        text-align: center;
    }

    .reduced-wrapper {
        color: white;
    }

    .section-title {
        width: 100%;
        margin-bottom: 25px;

        h1 {
            color: #FEB601;
        }
    }

    p {
        line-height: 30px;
        letter-spacing: 0.5px;
        font-family: $mirai-regular;
        font-size: 16px;
    }

    .icon-decor-wave {
        display: block;
        font-size: 60px;
        color: #FEB601;
    }

    .compatible-with-outer-wrapper {
        width: 100%;
        max-width: $max-width;
        position: absolute;
        top: auto;
        margin: auto;
        box-sizing: border-box;
        text-align: center;

        .compatible-with-inner-wrapper {
            background-color: white;
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@include media('>=tablet') {
    .brands {
        .centered {
            padding-top: 75px;
            padding-bottom: 300px;
        }

        .section-title {
            position: relative;
        }

        .reduced-wrapper {
            width: 800px;
            max-width: 100%;
            margin: auto;
        }

        p {
            max-width: 560px;
            margin: 0 auto 45px;
        }

        .brand {
            width: 25%;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 45px;
        }

        .compatible-with-outer-wrapper {
            bottom: -120px;
            height: 150px;

            .compatible-with-inner-wrapper {
                padding: 45px 0;
                margin: 0 $default-padding;
            }

            .compatible-with {
                width: 33.33%;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@include media('<tablet') {
    .brands {
        .hero {
            max-height: 980px;
        }

        .centered {
            padding: 70px $default-padding-mobile;
            padding-bottom: 500px;
        }

        p {
            margin: 0;
            margin-bottom: 20px;
        }

        .brand {
            padding: 10px 0;
        }

        .compatible-with-outer-wrapper {
            height: auto;
            bottom: -320px;

            .compatible-with-inner-wrapper {
                padding: 20px 0;
                margin: 0 $default-padding-mobile;

                .compatible-with {
                    padding: 20px 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .brands {
        .hero {
            max-height: 1000px;
        }
    }
}

@media only screen and (max-width: 540px) {
    .brands {
        .hero {
            max-height: 1050px;
        }
    }
}

@media only screen and (max-width: 440px) {
    .brands {
        .hero {
            max-height: 1100px;
        }
    }
}

@media only screen and (max-width: 340px) {
    .brands {
        .hero {
            max-height: 1150px;
        }
    }
}
