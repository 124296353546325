@import './common.scss';
@import './icons.scss';
@import '~normalize.css/normalize.css';

html, body {
    width: 100%;
    overflow-x: hidden;
}

html {
    &.no-scroll {
        overflow-y: hidden;
    }
}

body {
    background-color: #394856;
    font-family: $mirai-regular;
}

#root { // sass-lint:disable-line no-ids
    overflow-x: hidden;
}

.inteligable-wrapper {
    background-color: white;
    overflow-x: hidden;
}

.centered {
    margin: 0 auto;
    box-sizing: border-box;
}

header {
    height: $header-height;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    background-color: transparent;
    border-bottom: 2px solid rgba(57, 72, 86, 0.07);

    &.blue-header {
        background-color: $brand-primary-color;

        nav {
            .menu {
                line-height: $header-height;
            }

            ul {
                li {
                    a {
                        color: #818A94;
                    }
                }
            }
        }
    }

    nav {
        max-width: $max-width;
        height: $header-height;
        box-sizing: border-box;
        line-height: $header-height;

        .logo-wrapper {
            display: inline-block;
            margin-top: 12px;

            img {
                display: inline-block;
            }
        }

        ul {
            top: $header-height;
            padding: 0;
            margin: 0;

            li {
                height: 100%;
                position: relative;
                display: inline-block;

                &.portal-link {
                    padding: 0 39px;

                    &::before {
                        width: 2px;
                        height: 28px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        content: '';
                        background-color: #818A94;
                    }
                }

                a {
                    letter-spacing: 1px;
                    font-family: $mirai-bold;
                    font-size: 15px;
                    text-decoration: none;
                    color: #ADB1B8;

                    &.active {
                        color: white;
                    }
                }
            }
        }
    }
}

footer {
    width: 100%;
    position: relative;
    background-color: #394856;

    .centered {
        max-width: $max-width;
        position: relative;
        box-sizing: border-box;
        font-family: $mirai-regular;
        font-size: 14px;
        color: #9EA5AC;

        .developed-by {
            .icon-heart-2 {
                color: $red;
            }

            a {
                text-decoration: none;
                color: white;
            }
        }
    }

    .footer-section {
        vertical-align: top;
        text-align: left;

        &.address-section {
            .icon-location {
                display: inline-block;
                vertical-align: top;
                font-size: 20px;
                color: white;
            }

            .address {
                display: inline-block;
                vertical-align: top;
                margin-left: 15px;

                span {
                    display: block;
                }
            }
        }

        &.contact-info {
            div {
                margin-bottom: 10px;
            }

            .icon {
                display: inline-block;
                vertical-align: middle;
                font-size: 20px;
                color: white;
            }

            a {
                margin-left: 15px;
                text-decoration: none;
                color: #9EA5AC;
            }
        }

        &.memberships {
            img {
                margin-left: -10px;
                margin-right: 5px;
            }
        }

        h6 {
            font-family: $mirai-bold;
            font-size: 14px;
        }

        .section-info {
            line-height: 18px;

            .icon-decor-wave {
                margin-left: -5px;
                font-size: 100px;
                color: #4E7CDD;
            }

            &.caption {
                width: 155px;
            }
        }
    }
}

.hero {
    @include image169;
    min-height: 500px;
    max-height: 700px;
    overflow: hidden;
    background-color: #394856;

    &.section-hero {
        min-height: 400px;
        max-height: 570px;

        .hero-title {
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            transform: translate(-50%, -50%);
            text-align: center;
            text-transform: none;
            letter-spacing: 1px;
            line-height: 47px;
            font-size: 38px;
            font-family: $mirai-bold;
            color: white;
        }
    }
}

.section-title {
    h1 {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        font-size: 24px;
        font-family: $mirai-bold;
        letter-spacing: 1px;

        &.with-line {
            position: relative;
            margin-left: 60px;

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -60px;
                width: 45px;
                height: 4px;
                content: '';
            }
        }
    }
}

.definition-wrapper {
    .left-column {
        .word {
            display: block;
            margin-bottom: 10px;
            letter-spacing: 0.5px;
            font-family: $mirai-bold;
            color: $blue;
        }

        .origin {
            line-height: 28px;
            font-family: $mirai-regular;
            font-size: 16px;
            color: $green;

            &.italic {
                font-style: italic;
            }
        }
    }

    .right-column {
        font-family: $mirai-regular;
        font-size: 16px;
        line-height: 28px;
        color: $brand-primary-color;

        .definition {
            &:first-child {
                padding-bottom: 10px;
            }

            .bold {
                font-family: $mirai-bold;
            }

            .definition-class {
                color: $blue;
            }
        }
    }

    .colors-bar {
        width: 100%;
        line-height: 0;

        div {
            width: 20%;
            height: 12px;
            display: inline-block;

            &.first {
                background-color: $yellow;
            }

            &.second {
                background-color: $blue;
            }

            &.third {
                background-color: $green;
            }

            &.fourth {
                background-color: $red;
            }

            &.fifth {
                background-color: $gray;
            }
        }
    }
}

@keyframes fadein {
    from { opacity: 0.3; }
    to   { opacity: 1; }
}

@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@include media('>=tablet') {
    .centered {
        min-width: $min-width;
        max-width: $max-width;
        padding: 0 $default-padding;

        &.main-content {
            padding: 70px $default-padding;
        }
    }

    header {
        nav {
            min-width: $min-width;
            padding: 0 $default-padding;
            margin: 0 auto;

            .logo-wrapper {
                &:hover {
                    cursor: pointer;
                }
            }

            .menu {
                display: none;
            }

            ul {
                height: 100%;
                float: right;
                list-style: none;

                li {
                    display: inline-block;
                    vertical-align: top;
                    padding: 0 13px;

                    &.portal-link {
                        &::before {
                            width: 2px;
                            height: 28px;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 13px;
                        }
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    a {
                        &:hover {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    footer {
        .centered {
            min-width: $min-width;
            padding: 0 $default-padding;
            margin: 0 auto;
            text-align: justify;
            text-justify: distribute-all-lines;

            &.reserved-developed {
                padding-bottom: 10px;
            }

            // This is needed to correctly justify all the columns.
            &::after {
                width: 100%;
                display: inline-block;
                font-size: 0;
                line-height: 0;
                content: '';
            }

            .reserved-rights, .developed-by {
                display: inline-block;
            }

            .developed-by {
                float: right;
            }

            .footer-section {
                display: inline-block;
                margin: 45px 0 10px;
                margin-left: 0;

                &.memberships {
                    .section-info {
                        margin-top: 20px;
                    }
                }

                h6 {
                    margin-top: 60px;
                    margin-bottom: 10px;
                }

                .section-info {
                    min-height: 70px;

                    &.wave {
                        height: auto;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    .definition-wrapper {
        padding-top: 140px;

        .centered {
            text-align: center;
        }

        .left-column, .right-column {
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
            text-align: left;
            box-sizing: border-box;
        }

        .left-column {
            width: 45%;
            max-width: 390px;

            .word {
                font-size: 46px;
            }
        }

        .right-column {
            width: 55%;
            max-width: 400px;
        }

        .colors-bar {
            padding-top: 140px;
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@include media('<tablet') {
    .centered {
        padding: 0 $default-padding-mobile;

        &.main-content {
            padding: 70px $default-padding-mobile;
        }
    }

    header {
        nav {
            padding: 0 $default-padding-mobile;

            .menu {
                width: 50px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                display: inline-block;
                text-align: center;
                font-size: 23px;
                color: #ADB1B8;
            }

            ul {
                position: absolute;
                right: 0;
                left: 0;
                z-index: 10;
                padding-bottom: 40px;
                background-color: $brand-primary-color;
                border-bottom: 2px solid rgba(#524E4B, 0.1);
                text-align: center;

                &.hidden {
                    display: none;
                }

                li {
                    display: block;
                    margin-top: 40px;
                    line-height: normal;

                    &.portal-link {
                        margin-top: 60px;

                        &::before {
                            width: 40px;
                            height: 2px;
                            top: -30px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    a {
                        line-height: 30px;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    footer {
        position: relative;

        .centered {
            padding: 45px $default-padding-mobile;

            &.reserved-developed {
                padding-top: 0;

                .reserved-rights {
                    margin-bottom: 20px;
                }
            }

            .footer-section {
                margin-bottom: 45px;

                .section-info {
                    margin-bottom: 15px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.memberships {
                    img {
                        height: 30px;
                        margin-left: -15px;
                    }
                }
            }
        }
    }

    .section-title {
        text-align: middle;
    }

    .definition-wrapper {
        padding-top: 80px;

        .left-column {
            margin-bottom: 60px;

            .word {
                font-size: 40px;
            }
        }

        .colors-bar {
            padding-top: 80px;
        }
    }
}
