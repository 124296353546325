
@mixin bg-image {
    > img, > div, > video {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
    }
}

@mixin image169 {
    @include bg-image;
    position: relative;

    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 56.25%;
    }
}

@mixin service-item {
    text-align: center;
    color: #ADB1B8;

    .icon {
        display: block;
        text-align: center;
        font-size: 60px;
        color: #ADB1B8;
    }

    .title {
        display: block;
        margin-top: 15px;
        letter-spacing: 0.5px;
        font-family: $mirai-bold;
        font-size: 16px;
    }
}

@mixin button-more {
    display: inline-block;
    margin-top: 25px;
    text-decoration: none;
    letter-spacing: 0.5px;
    line-height: 20px;
    font-family: $mirai-bold;
    font-size: 16px;

    .icon-plus {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
    }
}
