@import '~include-media/dist/_include-media.scss';
@import './fonts.scss';

$breakpoints: (phone: 320px, tablet: 850px, desktop: 1024px);

// -----------------------------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------------------------

$header-height: 55px;

$min-width: 800px;
$max-width: 1280px;

$default-padding: 40px;
$default-padding-mobile: 14px;

$mirai-regular: 'Mirai-Regular';
$mirai-bold: 'Mirai-Bold';

// -----------------------------------------------------------------------------------------------
// Colors
// -----------------------------------------------------------------------------------------------

$brand-primary-color: #243746;

$red: #FC4236;
$yellow: #FEB601;
$blue: #4D89E6;
$green: #00C08A;
$gray: #ACB3B9;

@import './mixins';
