@import './common.scss';

.about {
    background-color: #F2F3F4;

    p {
        margin-top: 30px;
        line-height: 30px;
        font-family: $mirai-regular;
        font-size: 16px;
        color: $brand-primary-color;
    }

    h1, h3 {
        letter-spacing: 0.5px;
        font-family: $mirai-bold;
    }

    h1 {
        letter-spacing: 0.5px;
        font-size: 24px;
        color: $green;

        &.with-line {
            position: relative;
            margin: 0;
            margin-left: 60px;

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -60px;
                width: 45px;
                height: 4px;
                background-color: $green;
                content: '';
            }
        }
    }

    h3 {
        font-size: 16px;
    }

    .memberships {
        margin-top: 90px !important; // sass-lint:disable-line no-important
        text-align: center;

        p {
            margin-bottom: 70px;
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@include media('>=tablet') {
    .about {
        .about-description-wrapper, .history-description-wrapper {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
        }

        .about-description-wrapper {
            padding-right: 30px;
        }

        .history-description-wrapper {
            padding-left: 30px;
        }

        .memberships {
            max-width: 630px;
            margin: auto;

            .membership {
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@include media('<tablet') {
    .about {
        .about-description-wrapper {
            margin-bottom: 90px;
        }

        .memberships {
            .membership {
                display: block;
                margin: 75px auto;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
