@import './common.scss';

.contact {
    background-color: #F2F3F4;

    .dialog-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#2C353E, .7);
        z-index: 1;

        .dialog {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 45px 15px;
            overflow: hidden;
            background-color: white;
            border: 1px solid #EDEEF0;
            text-align: center;

            .text {
                font-family: $mirai-regular;
                font-size: 16px;
                color: #394856;

                p {
                    margin: 0;
                    line-height: 30px;
                    letter-spacing: 0.5px;
                }
            }

            button {
                padding: 0;
                margin: 0;
                margin-top: 30px;
                line-height: 36px;
                letter-spacing: 1px;
                font-size: 24px;
                font-family: $mirai-bold;
                text-transform: lowercase;
                border: none;
                background-color: transparent;
                color: $green;
            }
        }
    }

    .section-title {
        h1 {
            position: relative;
            color: $yellow;

            &::before {
                background-color: $yellow;
            }
        }

        p {
            padding-bottom: 40px;
            font-family: $mirai-regular;
            line-height: 30px;
            letter-spacing: 0.5px;
            font-size: 16px;
            color: #394856;
        }
    }

    .contact-info {
        padding: 45px 20px;
        box-sizing: border-box;
        text-align: center;
        background-color: $yellow;
        border-radius: 3px;
        color: white;

        span {
            display: block;
            margin-bottom: 25px;
            letter-spacing: 0.5px;
            font-family: $mirai-regular;
        }

        .icon-whatsapp {
            display: inline;
            margin: 0 10px;
            padding: 0;
            opacity: 0.7;
            font-size: 30px;
            vertical-align: middle;
        }

        a {
            display: block;
            text-decoration: none;
            letter-spacing: 1px;
            line-height: 36px;
            font-size: 24px;
            font-family: $mirai-bold;
            color: white;
        }
    }

    h3 {
        margin: 0;
        text-align: center;
        font-family: $mirai-bold;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.5px;
        color: $brand-primary-color;
    }

    form {
        .left-column, textarea {
            font-family: $mirai-regular;
        }

        .left-column {
            input {
                &[type='text'] {
                    width: 100%;
                    height: 60px;
                    display: block;
                    padding: 20px;
                    box-sizing: border-box;
                    background-color: white;
                    border: none;
                    border-radius: 3px;
                    letter-spacing: 0.5px;
                    outline: 0;
                    color: #394856;

                    &::placeholder {
                        color: #AEB4BA;
                    }

                    &:disabled {
                        border-color: #E7E8EB;
                        color: #D8D9DC;
                    }

                    &.invalid {
                        border-color: #FC4236;
                        color: #FC4236;

                        &::placeholder {
                            color: #FC4236;
                        }
                    }
                }
            }
        }

        .right-column {
            textarea {
                width: 100%;
                height: 260px;
                padding: 20px;
                box-sizing: border-box;
                background-color: white;
                border: none;
                border-radius: 3px;
                letter-spacing: 0.5px;
                resize: none;
                outline: 0;
                color: #394856;

                &::placeholder {
                    color: #AEB4BA;
                }

                &:disabled {
                    border-color: #E7E8EB;
                    color: #D8D9DC;
                }

                &.invalid {
                    border-color: #FC4236;
                    color: #FC4236;

                    &::placeholder {
                        color: #FC4236;
                    }
                }
            }

            button {
                float: right;
                display: block;
                padding: 0;
                margin: 0;
                margin-top: 10px;
                border: none;
                line-height: 30px;
                background-color: transparent;
                font-family: $mirai-bold;
                font-size: 16px;
                letter-spacing: 0.5px;
                text-transform: lowercase;
                color: $green;

                &:disabled {
                    color: #AEB4BA;
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@include media('>=tablet') {
    .contact {
        .dialog-backdrop {
            .dialog {
                width: 400px;
                left: 0;
                right: 0;
                margin: auto;

                button {
                    &:hover {
                        color: #00B688;
                        cursor: pointer;
                    }
                }
            }
        }

        .section-title {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding-right: 10px;
            box-sizing: border-box;
        }

        .contact-info-wrapper {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding-left: 10px;
            box-sizing: border-box;
        }

        h3 {
            margin-top: 80px;
            margin-bottom: 55px;
        }

        form {
            position: relative;
            text-align: center;

            .left-column {
                padding-right: 10px;
                box-sizing: border-box;

                input {
                    &[type='text'] {
                        margin-bottom: 40px;
                    }
                }
            }

            .left-column, .right-column {
                width: 50%;
                max-width: 500px;
                display: inline-block;
                vertical-align: top;
            }

            .right-column {
                padding-left: 10px;
                box-sizing: border-box;

                button {
                    &:hover {
                        color: #00B688;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@include media('<tablet') {
    .contact {
        .dialog-backdrop {
            .dialog {
                left: $default-padding-mobile;
                right: $default-padding-mobile;
            }
        }

        .centered {
            padding-top: 45px;
            padding-bottom: 90px;
        }

        .contact-info {
            margin-bottom: 40px;
        }

        h3 {
            margin-top: 50px;
            margin-bottom: 45px;
        }

        form {
            .left-column {
                input {
                    &[type='text'] {
                        margin-bottom: 20px;
                    }
                }
            }

            .right-column {
                textarea {
                    margin-top: 40px;
                }
            }
        }
    }
}
