@import './common.scss';

.spaces {
    background-color: #F2F3F4;

    .section-intro {
        margin-bottom: 40px !important; // sass-lint:disable-line no-important
        font-size: 16px;
        font-family: $mirai-regular;
        line-height: 28px;
        color: $brand-primary-color;
        text-align: center;
    }

    .section-title {
        h1 {
            margin-bottom: 25px;
            color: $blue;

            &::before {
                background-color: $blue;
            }
        }
    }

    .specialty-wrapper {
        padding: 15px 0;

        .specialty {
            background-color: white;
            border-radius: 3px;
            box-shadow: 0 0 25px 2px rgba(0, 0, 0, 0.08);

            h6 {
                padding-bottom: 15px;
                margin: 0;
                line-height: 20px;
                letter-spacing: 0.5px;
                font-family: $mirai-bold;
                font-size: 16px;
                color: $brand-primary-color;
            }

            .specialty-service {
                padding: 15px 0;

                p {
                    line-height: 28px;
                    font-family: $mirai-regular;
                    font-size: 16px;
                }

                .service-brands {
                    img {
                        height: 44px;
                        width: 185px;
                    }
                }
            }
        }
    }

    .services-section {
        padding-top: 70px;
        text-align: center;

        .intro {
            margin-top: 0;
            margin-bottom: 35px;
            font-family: $mirai-regular;
            font-size: 16px;
            line-height: 28px;
            color: $brand-primary-color;
        }

        .services-wrapper {
            .service-wrapper {
                display: inline-block;
                padding: 0 30px;

                .service {
                    @include service-item;
                }
            }
        }

        a {
            &.more {
                @include button-more;
                color: $blue;
            }
        }
    }

    .compatible-with {
        hr {
            margin: 70px 120px;
            border: none;
            border-bottom: 3px solid #ADB1B8;
            opacity: 0.2;
        }

        h5 {
            margin: 0;
            margin-bottom: 55px;
            text-align: center;
            font-size: 24px;
            font-family: $mirai-bold;
            line-height: 30px;
            letter-spacing: 0.5px;
            color: $brand-primary-color;
        }

        .assistant-wrapper {
            position: relative;

            .assistant-image {
                @include bg-image;
                position: relative;
                overflow: hidden;
            }

            .assistant-logo {
                padding: 20px 10px;
                background-color: white;
                text-align: center;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@include media('>=tablet') {
    .spaces {
        .section-intro {
            max-width: 865px;
            margin: auto;
        }

        .specialty-wrapper {
            .specialty {
                padding: 40px;

                .specialty-service {
                    img, p, .service-brands {
                        display: inline-block;
                        vertical-align: top;
                    }

                    p {
                        width: 100%;
                        padding-right: 635px;
                        padding-left: 125px;
                        margin: 0;
                        margin-right: -615px;
                        margin-left: -95px;
                        box-sizing: border-box;
                    }

                    .service-brands {
                        img {
                            padding: 0 10px;
                        }
                    }
                }
            }
        }

        .services-section {
            .intro {
                max-width: 580px;
                margin: auto;
            }

            .services-wrapper {
                padding: 40px 0;
            }
        }

        .compatible-with {
            padding-bottom: 70px;

            .assistant-wrapper {
                width: 33.33%;
                display: inline-block;

                .assistant-image {
                    height: 340px;
                }

                .assistant-logo {
                    position: absolute;
                    right: 30px;
                    left: 30px;
                    bottom: -70px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .spaces {
        .specialty-wrapper {
            .specialty {
                .specialty-service {
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        padding-right: 0;
                        padding-bottom: 30px;
                        margin-right: 0;
                    }

                    .service-brands {
                        width: 100%;
                        padding-left: 125px;
                        box-sizing: border-box;
                        text-align: center;

                        img {
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@include media('<tablet') {
    .spaces {
        .specialty-wrapper {
            .specialty {
                padding: 50px 30px;
                padding-bottom: 35px;

                h6 {
                    text-align: center;
                }

                .specialty-service {
                    margin-bottom: 50px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    img, p, .service-brands {
                        display: block;
                        margin: auto;
                    }

                    p {
                        padding: 15px 0;
                    }

                    .service-brands {
                        padding: 0;

                        img {
                            padding: 15px 0;
                        }
                    }
                }
            }
        }

        .services-section {
            .services-wrapper {
                max-width: 600px;
                padding: 20px 0;
                margin: auto;

                .service-wrapper {
                    width: 50%;
                    max-width: 165px;
                    padding: 20px 10px;
                    box-sizing: border-box;

                    .service {
                        width: 100%;
                    }
                }
            }
        }

        .compatible-with {
            .assistant-wrapper {
                max-width: 400px;
                margin: auto;

                .assistant-image {
                    max-width: 400px;
                    min-height: 340px;
                    margin: auto;
                }
            }
        }
    }
}
