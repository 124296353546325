@font-face {
    font-family: 'Inteligable-Icons';
    src: url('../fonts/Inteligable-Icons.ttf?r9rnd5') format('truetype'),
    url('../fonts/Inteligable-Icons.woff?r9rnd5') format('woff'),
    url('../fonts/Inteligable-Icons.svg?r9rnd5#Inteligable-Icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Inteligable-Icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    // line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-access:before {
    content: "\e900";
}
.icon-alarms:before {
    content: "\e901";
}
.icon-alert:before {
    content: "\e902";
}
.icon-attach:before {
    content: "\e903";
}
.icon-audio:before {
    content: "\e904";
}
.icon-audiovideo:before {
    content: "\e905";
}
.icon-blinds:before {
    content: "\e906";
}
.icon-cabling:before {
    content: "\e907";
}
.icon-cctv:before {
    content: "\e908";
}
.icon-close:before {
    content: "\e909";
}
.icon-decor-l:before {
    content: "\e90a";
}
.icon-decor-ll:before {
    content: "\e90b";
}
.icon-decor-n:before {
    content: "\e90c";
}
.icon-decor-o:before {
    content: "\e90d";
}
.icon-decor-title:before {
    content: "\e90e";
}
.icon-decor-wave:before {
    content: "\e90f";
}
.icon-design:before {
    content: "\e910";
}
.icon-engineering:before {
    content: "\e911";
}
.icon-evaluation:before {
    content: "\e912";
}
.icon-firealarm:before {
    content: "\e913";
}
.icon-go-left:before {
    content: "\e914";
}
.icon-go-right:before {
    content: "\e915";
}
.icon-heart-2:before {
    content: "\e916";
}
.icon-heart:before {
    content: "\e917";
}
.icon-installation:before {
    content: "\e918";
}
.icon-integration:before {
    content: "\e919";
}
.icon-lights:before {
    content: "\e91a";
}
.icon-location:before {
    content: "\e91b";
}
.icon-mail:before {
    content: "\e91c";
}
.icon-maintenance:before {
    content: "\e91d";
}
.icon-menu:before {
    content: "\e91e";
}
.icon-phone-2:before {
    content: "\e91f";
}
.icon-phone:before {
    content: "\e920";
}
.icon-plus:before {
    content: "\e921";
}
.icon-programming:before {
    content: "\e922";
}
.icon-rack:before {
    content: "\e923";
}
.icon-sprinkler:before {
    content: "\e924";
}
.icon-support:before {
    content: "\e925";
}
.icon-temperature:before {
    content: "\e926";
}
.icon-training:before {
    content: "\e927";
}
.icon-video:before {
    content: "\e928";
}
.icon-whatsapp:before {
    content: "\e929";
}
.icon-wifi:before {
    content: "\e92a";
}
