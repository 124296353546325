@import './common.scss';

.not-found {
    .wrapper {
        margin-top: $header-height;
        overflow: auto;
        background-position: center;
        background-size: cover;
        color: white;

        .text {
            text-align: center;
            margin: auto;
            font-family: $mirai-bold;

            h1 {
                margin: 0;
                color: $green;
            }

            .icon-decor-wave {
                display: block;
                margin-bottom: 20px;
                font-size: 80px;
                color: $green;
            }

            p {
                line-height: 30px;
                letter-spacing: 0.5px;
                font-size: 24px;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@media only screen and (min-width: 801px) {
    .not-found {
        .wrapper {
            padding: 100px 0;
            background-image: url('../assets/404@2x.png');

            .text {
                h1 {
                    letter-spacing: 10px;
                    font-size: 140px;
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@media only screen and (max-width: 800px) {
    .not-found {
        .wrapper {
            padding: 40px 0;
            background-image: url('../assets/404-mobile@2x.png');

            .text {
                width: 100%;
                padding: 0 20px;
                box-sizing: border-box;

                h1 {
                    letter-spacing: 7.5px;
                    font-size: 110px;
                }
            }
        }
    }
}
